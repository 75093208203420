import { useNuxtApp } from "#imports";
import { useQueryClient } from "@tanstack/vue-query";
import { unref, type MaybeRef } from "vue";
import { z } from "zod";
import useNotify from "~/composables/useNotify";
import { deserialiseCaseHistoryEntry } from "~/src/models/Case/Case.model";
import {
  deserialiseScreenResult,
  type BffScreenResultViewModel,
  type ScreenResultViewModel,
} from "~/src/models/Case/Screen.model";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";
import {
  deserialiseCase,
  deserialiseCaseHistoryComponentData,
  deserialisePagedCaseSummary,
} from "../models/Case/Case.model";

import type { SetCaseStatusPayload } from "~/server/api/cm/case/[id]/status.put";
import type { BulkAssignUserPayload } from "~/server/api/cm/case/bulk-assign.post";
import type { GetCasesBody } from "~/server/api/cm/cases.post";
import type { CaseNoteEntry } from "../models/Case/History.model";
import type { FrontendRequestObject } from "../models/utils/Api.model";

const assignment = z.enum(["unassigned", "mine", "all"]).optional();
export const listCasesRequestToQueryObject = (
  req: FrontendRequestObject
): GetCasesBody => {
  return {
    page: req.page,
    assignment: assignment.parse(req.meta?.assignment),
    pageSize: req.pageSize,
    sortBy: req.sortBy,
    sortOrder: req.sortOrder,
    filters: req.filters,
  };
};

const endpoints = {
  list: "/api/cm/cases",
  get: parseUriTemplate("/api/cm/case/{id}"),
  status: { update: parseUriTemplate("/api/cm/case/{id}/status") },
  assignment: {
    post: parseUriTemplate("/api/cm/case/{id}/assignment"),
    get: parseUriTemplate("/api/cm/case/{id}/assigned-to"),
    delete: parseUriTemplate("/api/cm/case/{id}/assignment"),
    bulk: "/api/cm/case/bulk-assign",
  },
  history: {
    get: parseUriTemplate("/api/cm/case/{id}/history"),
  },
  screen: {
    get: parseUriTemplate("/api/cm/case/{id}/screen-results"),
  },
  notes: {
    get: parseUriTemplate("/api/cm/case/{id}/notes"),
    post: parseUriTemplate("/api/cm/case/{id}/notes"),
  },
  dueDate: {
    update: parseUriTemplate("/api/cm/case/{id}/due-date"),
  },
} as const;

export const useCaseService = () => {
  const { $api } = useNuxtApp();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();

  const listCases = async (x: GetCasesBody, signal?: AbortSignal) => {
    const res = await $api(endpoints.list, {
      signal,
      method: "POST",
      body: x,
    });
    return deserialisePagedCaseSummary(res);
  };
  const useListCaseQuery = (
    x: MaybeRef<FrontendRequestObject>,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Cases.list, x],
      ({ signal }) => {
        const req = unref(x);
        return listCases(listCasesRequestToQueryObject(req), signal);
      },
      { createNuxtError }
    );

  const getCase = async (
    id: string,
    includeLatestScreening: boolean,
    signal?: AbortSignal
  ) => {
    const res = await $api(
      endpoints.get.expand({ id: encodeURIComponent(id) }),
      {
        signal,
        query: {
          includeLatestScreening: includeLatestScreening ? "true" : "false",
        },
      }
    );
    return {
      result: deserialiseCase(res.result as any),
      riskSummary: res.riskSummary,
    };
  };
  const useGetCaseQuery = (
    id: string,
    includeLatestScreening = false,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Cases.get, id],
      ({ signal }) => getCase(id, includeLatestScreening, signal),
      {
        createNuxtError,
      }
    );

  const setCaseStatus = (caseId: string, payload: SetCaseStatusPayload) =>
    $api(endpoints.status.update.expand({ id: caseId }), {
      method: "PUT",
      body: {
        ...payload,
      },
    });

  const useSetCaseStatusMutation = () =>
    createMutation(
      async ({
        caseId,
        values,
      }: {
        caseId: string;
        values: SetCaseStatusPayload;
      }) => {
        if (caseId === null) return null;
        return await setCaseStatus(caseId, {
          status: values.status,
          note: values.note,
          metadata: values.metadata,
        });
      },
      {
        onSuccess: (_, { caseId }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.get, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.history, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.notes, caseId],
          });
        },
        onError: () => notifyError("Error setting case status"),
      }
    );

  const assignCaseUser = (caseId: string, assignedTo: string) =>
    $api(endpoints.assignment.post.expand({ id: caseId }), {
      method: "POST",
      body: {
        assignedTo: assignedTo,
      },
    });

  const getAssignedTo = (caseId: string) =>
    $api(endpoints.assignment.get.expand({ id: caseId }));
  const getAllRulesFromScreeningResults = (
    screeningResults: ScreenResultViewModel
  ) => {
    const rules = new Set<{ id: string; name: string; condition: string }>();

    screeningResults.hits.forEach((hit) => {
      rules.add(hit.rule);
    });

    return Array.from(rules);
  };

  const refreshCaseTable = () =>
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.Cases.list],
    });
  const useAssignCaseUserMutation = () =>
    createMutation(
      ({ caseId, assignedTo }: { caseId: string; assignedTo: string }) =>
        assignCaseUser(caseId, assignedTo),
      {
        onSuccess: (_, { caseId }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.get, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.history, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.list],
          });
        },
      }
    );

  const unAssignCaseUser = (caseId: string) =>
    $api(endpoints.assignment.delete.expand({ id: caseId }), {
      method: "DELETE",
    });
  const useUnAssignCaseUserMutation = () =>
    createMutation(
      async ({ caseId }: { caseId: string }) => {
        if (caseId === null) return null;
        return await unAssignCaseUser(caseId);
      },
      {
        onSuccess: (_, { caseId }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.get, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.history, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.list],
          });
        },
      }
    );

  const bulkAssignCaseUser = (payload: BulkAssignUserPayload) =>
    $api(endpoints.assignment.bulk, {
      method: "POST",
      body: payload,
    });
  const useBulkAssignCaseUserMutation = () =>
    createMutation(
      (payload: BulkAssignUserPayload) => bulkAssignCaseUser(payload),
      {
        onSuccess: (x, { caseIds }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.list],
          });

          caseIds.forEach((caseId) => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.Cases.get, caseId],
            });
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.Cases.history, caseId],
            });
          });

          notifySuccess("Success", `${caseIds.length} users assigned`);
        },
        onError: () => notifyError("Error assigning users"),
      }
    );

  const getCaseHistory = async (id: string, signal?: AbortSignal) => {
    const res = await $api(endpoints.history.get.expand({ id }), { signal });
    return res.result.map(deserialiseCaseHistoryComponentData);
  };
  const useGetCaseHistoryQuery = (id: string, createNuxtError = true) =>
    createQuery(
      [QUERY_KEYS.Cases.history, id],
      ({ signal }) => getCaseHistory(id, signal),
      {
        createNuxtError,
      }
    );

  // Case Screen Results
  const getCaseScreenResults = async (
    id: string,
    screeningId?: string,
    signal?: AbortSignal
  ) => {
    const res = await $api(endpoints.screen.get.expand({ id }), {
      query: {
        screeningId,
      },
      signal,
    });

    // same stupid type error with SerialisedDate
    return deserialiseScreenResult(res as BffScreenResultViewModel);
  };
  const useGetCaseScreenResultsQuery = <Sus extends boolean = true>(
    _id: MaybeRef<string | null>,
    screeningId: MaybeRef<string | null>,
    options?: MyQueryOptions<Sus>
  ) =>
    createQuery<ScreenResultViewModel | null, Sus>(
      [QUERY_KEYS.Cases.screenResults.get, _id, screeningId],
      ({ signal }) => {
        const id = unref(_id);
        if (!id) return null;
        return getCaseScreenResults(
          id,
          unref(screeningId) ?? undefined,
          signal
        );
      },
      {
        createNuxtError: true,
        ...options,
      }
    );

  const getCaseNotes = async (id: string, signal?: AbortSignal) => {
    const res = await $api(endpoints.notes.get.expand({ id }), { signal });
    return res.map(deserialiseCaseHistoryEntry) as CaseNoteEntry[];
  };
  const useGetCaseNotesQuery = (id: string, createNuxtError = true) =>
    createQuery(
      [QUERY_KEYS.Cases.notes, id],
      ({ signal }) => getCaseNotes(id, signal),
      {
        createNuxtError,
      }
    );

  const addCaseNote = (id: string, text: string) =>
    $api(endpoints.notes.get.expand({ id }), {
      method: "POST",
      body: {
        text,
      },
    });
  const useAddCaseNoteMutation = (caseId: string) =>
    createMutation((text: string) => addCaseNote(caseId, text), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Cases.notes, caseId],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Cases.history, caseId],
        });
      },
      onError: () => notifyError("Error adding note"),
    });

  const updateDueDate = (id: string, date: string | null) =>
    $api(endpoints.dueDate.update.expand({ id }), {
      method: "PUT",
      body: {
        dueDate: date,
      },
    });
  const useUpdateDateMutation = () =>
    createMutation(
      async ({ caseId, date }: { caseId: string; date: Date | null }) => {
        if (caseId === null) return null;
        return await updateDueDate(caseId, date?.toISOString() ?? null);
      },
      {
        onSuccess: (_, { caseId }) => {
          notifySuccess("Success", `Due date updated`);
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.get, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.history, caseId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Cases.list],
          });
        },
        onError: () => notifyError("Error updating due date"),
      }
    );

  return {
    assignCaseUser,
    bulkAssignCaseUser,
    getAllRulesFromScreeningResults,
    getCaseScreenResults,
    listCases,
    refreshCaseTable,
    setCaseStatus,
    getAssignedTo,
    listCasesRequestToQueryObject,

    useAddCaseNoteMutation,
    useAssignCaseUserMutation,
    useUnAssignCaseUserMutation,
    useBulkAssignCaseUserMutation,
    useGetCaseHistoryQuery,
    useGetCaseNotesQuery,
    useGetCaseQuery,
    useGetCaseScreenResultsQuery,
    useListCaseQuery,
    useSetCaseStatusMutation,
    useUpdateDateMutation,
  };
};
